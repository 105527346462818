/* You can add global styles to this file, and also import other style files */
//@import "./assets/css/bootstrap.min.css";
//@import "./assets/css/font-awesome.min.css";
// @import "./assets/css/owl.carousel.min.css";
// @import "./assets/css/owl.theme.default.min.css";
//@import "./assets/css/animate.css";
//@import "./assets/css/style.css";
//@import "./assets/css/responsive.css";



//@import "src/scss/variables";
//@import "src/scss/font";
//@import "src/scss/vincent";

//Bootstrap


.map-container {
  border-radius: 30px !important;
}

#ngb-typeahead-0 button .active {
  background-color: var(--bs-dropdown-link-active-bg) !important;
  color: white !important;
}

#ngb-typeahead-0 button {
  background-color: transparent;
  color: var(--bs-dropdown-color);
}

.pxp-jobs-card-1-company-logo {
  background-size: cover !important;
  background-position: top !important;
}

.pxp-cover {
  background-size: cover !important;
  background-position: center !important;
}

.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret::before {
  content: none !important;
}

.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret {
  right: 0px !important;
}

.dropdown-multiselect__caret {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.45rem center;
  background-size: 16px 12px;
}

@import "../node_modules/bootstrap/scss/bootstrap.scss";

// @import "~bootstrap/scss/functions";
// @import "~bootstrap/scss/variables";
// @import "~bootstrap/scss/mixins";
// @import "~bootstrap/scss/utilities";

// // Layout & components
// @import "~bootstrap/scss/root";
// @import "~bootstrap/scss/reboot";
// @import "~bootstrap/scss/type";
// @import "~bootstrap/scss/images";
// @import "~bootstrap/scss/containers";
// @import "~bootstrap/scss/grid";
// @import "~bootstrap/scss/tables";
// @import "~bootstrap/scss/forms";
// @import "~bootstrap/scss/buttons";
// @import "~bootstrap/scss/transitions";
// @import "~bootstrap/scss/dropdown";
// @import "~bootstrap/scss/button-group";
// @import "~bootstrap/scss/nav";
// @import "~bootstrap/scss/navbar";
// @import "~bootstrap/scss/card";
// @import "~bootstrap/scss/accordion";
// @import "~bootstrap/scss/breadcrumb";
// @import "~bootstrap/scss/pagination";
// @import "~bootstrap/scss/badge";
// @import "~bootstrap/scss/alert";
// @import "~bootstrap/scss/progress";
// @import "~bootstrap/scss/list-group";
// @import "~bootstrap/scss/close";
// @import "~bootstrap/scss/toasts";
// @import "~bootstrap/scss/modal";
// @import "~bootstrap/scss/tooltip";
// @import "~bootstrap/scss/popover";
// @import "~bootstrap/scss/carousel";
// @import "~bootstrap/scss/spinners";
// @import "~bootstrap/scss/offcanvas";

// // Helpers
// @import "~bootstrap/scss/helpers";

// // Utilities
// @import "~bootstrap/scss/utilities/api";
/* Importing Bootstrap SCSS file. */

label.required:after {
  content: "*";
  color: red;
}

.pxp-jobs-list-side-filter .input-group-text {
  width: 45px;
}

ng-multiselect-dropdown {
  width: calc(100% - 45px);
  ;
}

ng-multiselect-dropdown {
  border: 0 none;
  font-weight: 300;
  color: var(--pxpTextColor);
  background-color: #fff;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 1rem .75rem;
}

.multiselect-dropdown .dropdown-btn {
  display: flex;
  border: none !important;
  padding: 0 !important;
  margin-bottom: 0 !important;
  font-weight: 300 !important;
}

.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret {
  padding: 0 !important;
  height: 31px !important;
  width: 27px !important;
}

.multiselect-dropdown .dropdown-btn .selected-item {
  max-width: 100% !important;
  background: var(--pxpMainColor) !important;
  padding: 0 10px !important;
  border-radius: 25px !important;
}

a.pxp-jobs-card-1-company:hover,
a.pxp-jobs-card-2-company:hover,
a.pxp-jobs-card-3-company:hover,
a.pxp-jobs-card-1-company-logo:hover,
a.pxp-jobs-card-2-company-logo:hover,
a.pxp-jobs-card-3-company-logo:hover {
  cursor: pointer;
}

body {
  display: block !important;
}

 
// /* width */
// ::-webkit-scrollbar {
//   width: 4px;
// }

// /* Track */
// ::-webkit-scrollbar-track {
//   background: #f1f1f1;
//   border-radius: 10px;
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
//   background: #888;
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: #555;
// }
